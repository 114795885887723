// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './m3-theme';

@include mat.core();

@font-face {
    font-family: 'SystemFonts';
    src: local('system-ui'), local('Segoe UI'), local('Roboto'), local('Helvetica'), local('Arial'), local('sans-serif'),
        local('Apple Color Emoji'), local('Segoe UI Emoji');
}

:root {
    @include mat.core-theme(m3-theme.$light-theme);
    @include mat.system-level-colors(m3-theme.$light-theme);
    @include mat.system-level-typography(m3-theme.$light-theme);

    @include mat.all-component-themes(m3-theme.$light-theme);

    --base: 4px;

    .mdc-button {
        &.mat-secondary {
            @include mat.button-color(m3-theme.$light-theme, $color-variant: secondary);
        }

        &.mat-error {
            @include mat.button-color(m3-theme.$light-theme, $color-variant: error);
        }
    }

    .mdc-icon-button {
        &.mat-primary {
            @include mat.icon-button-color(m3-theme.$light-theme, $color-variant: primary);
        }

        &.mat-secondary {
            @include mat.icon-button-color(m3-theme.$light-theme, $color-variant: secondary);
        }

        &.mat-error {
            @include mat.icon-button-color(m3-theme.$light-theme, $color-variant: error);
        }
    }
}
